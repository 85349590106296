
















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class EmergenciesHelpContent extends Vue {}
